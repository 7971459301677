import React from "react";
import * as FaIcons from "react-icons/fa";
import {
  Customers,
  Dashboard,
  Data,
  Lable,
  Logout,
  Notifications,
  Orders,
  Payments,
  Printer,
  Reports,
  Support,
  Temperature
} from "./../../SvgIcons/allIcons";

export const SidebarData = [
  {
    icon: <Dashboard />,
    title: "sideBar.dashboard",
    path: "/admin/dashboard",
  },
  {
    icon: <Customers />,
    title: "sideBar.customers",
    iconClosed: <FaIcons.FaAngleDown />,
    iconOpened: <FaIcons.FaAngleUp />,
    subNav: [
      {
        title: "sideBar.companyManagement",
        path: "/admin/companymanagement",
        activePath: [
          "/admin/companymanagement",
          "/admin/addnewcompany",
          "/admin/companylocation",
          "/admin/companydetails",
        ],
      },

      {
        title: "sideBar.userManagement",
        path: "/admin/usermanagement",
        activePath: [
          "/admin/usermanagement",
          "/admin/addNewUser",
          "/admin/userdetail",
        ],
      },
      {
        title: "sideBar.brandManagement",
        path: "/admin/brand",
        activePath: ["/admin/brand"],
      },
    ],
  },

  {
    icon: <Payments />,
    title: "sideBar.paymentsBilling",
    path: "/admin/paymentandbilling",
  },
  {
    icon: <Orders />,
    title: "sideBar.ordersTransactions",
    path: "/admin/transactions",
  },
  // {
  //   icon: <Products />,
  //   title: "sideBar.products",
  //   path: "/admin/products",
  // },
  {
    icon: <Reports />,
    title: "sideBar.reportAnalytics",
    path: "/admin/reportsindex",
  },
  {
    icon: <Lable />,
    title: "sideBar.labelManagement",
    path: "/admin/label",
  },
  {
    icon: <Data />,
    title: "sideBar.dataManagement",
    iconClosed: <FaIcons.FaAngleDown />,
    iconOpened: <FaIcons.FaAngleUp />,
    subNav: [
      {
        title: "sideBar.categories",
        path: "/admin/categorylist",
        activePath: ["/admin/categorylist"],
      },
      {
        title: "sideBar.items",
        path: "/admin/itemslist",
        activePath: ["/admin/itemslist", "/admin/item-details"],
      },
      {
        title: "sideBar.users",
        path: "/admin/userlist",
        activePath: ["/admin/userlist", "/admin/user"],
      },
      {
        title: "sideBar.prepListBatches",
        path: "/admin/prep-list",
        activePath: ["/admin/prep-list", "/admin/add-prep-list"],
      },
      {
        title: "sideBar.taskList",
        path: "/admin/tasklist",
        activePath: [
          "/admin/tasklist",
          "/admin/addnewtask",
          "/admin/task-details",
        ],
      },
      {
        title: "sideBar.receivingList",
        path: "/admin/receivinglist",
        activePath: ["/admin/receivinglist", "/admin/addreceivinglist"],
      },
      {
        title: "sideBar.products",
        path: "/admin/product",
        activePath: ["/admin/product", "/admin/addproduct"],
      },
    ],
  },

  {
    icon: <Printer />,
    title: "sideBar.printerManagement",
    path: "/admin/printers",
    activePath: [
      "/admin/printers",
      "/admin/printers/modify",
    ],
  },
  {
    icon: <Temperature />,
    title: "sideBar.temperatureMonitoring",
    path: "",
  },
  {
    icon: <Notifications />,
    title: "sideBar.notificationNews",
    path: "/admin/notifications",
  },
  {
    icon: <Support />,
    title: "sideBar.supportTicket",
    path: "",
  },
  {
    icon: <Logout />,
    title: "sideBar.logout",
    type: "logout",
  },
];
