import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import useSlider from "../../hooks/useSlider";
import { getAllTransactions } from "../../services/services";
import { constant } from "../../utils/constants";
import Pagination from "../../utils/Pagination";
import Sidebar from "../sidebar/Sidebar";

const Orderindex = () => {
  const isSlider = useSlider();
  const [page, setPage] = useState(constant.PAGE_ONE);
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(constant.PER_PAGE_TEN);

  const { data } = useQuery({
    queryKey: ["user-list", page, limit],
    queryFn: async () => {
      let params = {
        page: page,
        limit: limit,
        type: constant.TRANSACTIONS_TYPE.TRANSACTION,
      };
      if (search) {
        params.search = search;
      }
      const resp = await getAllTransactions(params);
      return resp?.data?.data;
    },
  });
  return (
    <div className="mainbox">
      <Sidebar />
      <div className={isSlider ? "body-content close" : "body-content open"}>
        <Row>
          <Col xl={12} className="">
            <h2 className="mainhead font-24">Orders/Transactions</h2>
          </Col>
        </Row>
        <Row>
          {/* <Col xl={12}>
            <div className="sidebarBox mt-3">
              <div className="sidebar-box search-form-wrap mb-0">
                <div className="search-form">
                  <div className="form-group">
                    <span className="iconsearch">
                      <Search />
                    </span>
                    <input
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      onKeyDown={(e) => {
                        noSpecialChars(e);
                        if (e.key == "Enter" && search.trim() !== "") refetch();
                      }}
                      onKeyUp={(e) =>
                        e.target.value == "" &&
                        e.key == "Backspace" &&
                        refetch()
                      }
                      type="text"
                      className="form-control"
                      placeholder={t("globals.search")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Col> */}
        </Row>
        <div className="table-responsive tableOut mt-4">
          <table className="table tableDashboard">
            <thead className="thead-dark">
              <tr>
                <th>Order ID</th>
                <th>User Name</th>
                <th>Amount</th>
                <th>Date</th>
                <th>Time</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {data?.transactions && data?.transactions?.length > 0 ? (
                data?.transactions?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item?.orderId}</td>
                      <td>{item?.userDetails?.userInfo?.name}</td>
                      <td>${item?.amount}</td>
                      <td>{moment(item?.createdAt).format("ll")}</td>
                      <td>{moment(item?.createdAt).format("LT")}</td>
                      <td>
                        <p
                          className={
                            item?.status ==
                            constant?.TRANSACTION_STATUS?.SUCCESS
                              ? "greenBox"
                              : item?.status ==
                                constant?.TRANSACTION_STATUS?.PENDING
                              ? "greenBox yellowBox"
                              : "greenBox redBox"
                          }
                        >
                          {item?.status == constant?.TRANSACTION_STATUS?.SUCCESS
                            ? "Completed"
                            : item?.status ==
                              constant?.TRANSACTION_STATUS?.PENDING
                            ? "Pending"
                            : "Cancelled"}
                        </p>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={10} className="text-center">
                    No Transaction Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <Pagination
          page={page}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
          totalPages={data?.total_pages}
          total={data?.total}
          length={data?.transactions?.length}
        />
      </div>
    </div>
  );
};

export default Orderindex;
