import React from "react";
import { Route, Routes } from "react-router-dom";
import AddNewTask from "../admin/Datamanagement/AddNewTask";
import AddReceivingList from "../admin/Datamanagement/AddReceivingList";
import AddnewPreplist from "../admin/Datamanagement/AddnewPreplist";
import CategoryList from "../admin/Datamanagement/CategoryList";
import CompleteTaskdetail from "../admin/Datamanagement/CompleteTaskdetail";
import ItemDetails from "../admin/Datamanagement/ItemDetails";
import ItemsList from "../admin/Datamanagement/ItemsList";
import PrepList from "../admin/Datamanagement/PrepList";
import ProductList from "../admin/Datamanagement/ProductList";
import ReceivingList from "../admin/Datamanagement/ReceivingList";
import TaskList from "../admin/Datamanagement/TaskList";
import UserDetails from "../admin/Datamanagement/UserDetails";
import UserList from "../admin/Datamanagement/UserList";
import ChooseSize from "../admin/LabelManagement/ChooseSize";
import CreateLabel from "../admin/LabelManagement/CreateLabel";
import LabelManagement from "../admin/LabelManagement/LabelManagement";
import Orderindex from "../admin/Order&Transactions/Orderindex";
import Addproduct from "../admin/Product/Addproduct";
import Reportsindex from "../admin/Reports&Analytics/Reportsindex";
import AddNewUser from "../admin/Usermanagement/AddNewUser";
import Userdetail from "../admin/Usermanagement/Userdetail";
import Usermanagement from "../admin/Usermanagement/Usermanagemnt";
import Brand from "../admin/brandManagement/Brand";
import AddNewCompany from "../admin/companyManagement/AddNewCompany";
import BrandDetails from "../admin/companyManagement/BrandDetails";
import CompanyDetails from "../admin/companyManagement/CompanyDetails";
import CompanyLocation from "../admin/companyManagement/CompanyLocation";
import CompanyLocationDetails from "../admin/companyManagement/CompanyLocationDetails";
import CompanyManagement from "../admin/companyManagement/CompanyManagement";
import Dashboard from "../admin/dashboard/Dashboard";
import EditProfile from "../admin/dashboard/EditProfile";
import MyProfile from "../admin/dashboard/MyProfile";
import Notification from "../admin/notificationAndNews/Notification";
import PaymentAndBilling from "../admin/paymentAndBilling/PaymentAndBilling";
import TicketDetails from "../admin/supportTickets/TicketDetails";
import NotFound from "../components/NotFound";
import "./admin.css";
import PrinterManagement from "../admin/PrinterManagement/PrinterManagement";
import ModifyPrinter from "../admin/PrinterManagement/ModifyPrinter";

const AdminRoute = () => {
  return (
    <Routes>
      <Route path="/dashboard" element={<Dashboard />} />

      {/**********************Admin Profile***********************/}
      <Route path="/profile" element={<MyProfile />} />
      <Route path="/editprofile" element={<EditProfile />} />

      {/*********************All Brand Management********************/}
      <Route path="/brand" element={<Brand />} />

      {/************************Company Management*********************/}
      <Route path="/companymanagement" element={<CompanyManagement />} />
      <Route path="/addnewcompany" element={<AddNewCompany />} />
      <Route path="/companylocation/:header" element={<CompanyLocation />} />
      <Route path="/companydetails/:header" element={<CompanyDetails />} />
      <Route path="/brand/:id/:header" element={<BrandDetails />} />
      <Route
        path="/location/:id/:header"
        element={<CompanyLocationDetails />}
      />

      {/************************User Management**********************/}
      <Route path="/usermanagement" element={<Usermanagement />} />
      <Route path="/addNewUser" element={<AddNewUser />} />
      <Route path="/userdetail/:id" element={<Userdetail />} />

      {/**************************Product management*******************/}
      <Route path="/product" element={<ProductList />} />
      <Route path="/addproduct" element={<Addproduct />} />

      {/***************************Prep List***************************/}
      <Route path="/prep-list" element={<PrepList />} />
      <Route path="/add-prep-list" element={<AddnewPreplist />} />

      {/****************************Task Management********************/}
      <Route path="/tasklist" element={<TaskList />} />
      <Route path="/task-details/:id" element={<CompleteTaskdetail />} />
      <Route path="/addnewtask" element={<AddNewTask />} />

      {/****************************Receiving List********************/}
      <Route path="/receivinglist" element={<ReceivingList />} />
      <Route path="/addreceivinglist" element={<AddReceivingList />} />

      {/****************************Item List********************/}
      <Route path="/itemslist" element={<ItemsList />} />
      <Route path="/item-details/:id" element={<ItemDetails />} />

      {/****************************User List********************/}
      <Route path="/userlist" element={<UserList />} />
      <Route path="/user/:id" element={<UserDetails />} />

      {/***************************Printer managment*******************/}
      <Route path="/printers" element={<PrinterManagement />} />
      <Route path="/printers/modify/" element={<ModifyPrinter />} />

      {/***************************Label managment*******************/}
      <Route path="/label" element={<LabelManagement />} />
      <Route path="/choose-size" element={<ChooseSize />} />
      <Route path="/create-label" element={<CreateLabel />} />

      {/***************************Billing and Payments*******************/}
      <Route path="/paymentandbilling" element={<PaymentAndBilling />} />
      <Route path="/transactions" element={<Orderindex />} />

      <Route path="/reportsindex" element={<Reportsindex />} />
      <Route path="/notifications" element={<Notification />} />
      <Route path="/ticketDetails" element={<TicketDetails />} />
      <Route path="/categorylist" element={<CategoryList />} />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
export default AdminRoute;
