import { useQueries } from "@tanstack/react-query";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import useSlider from "../../hooks/useSlider";
import Sidebar from "../sidebar/Sidebar";
import "./../../admin/Usermanagement/addNewUser.css";
import { getItemReports, getReports } from "../../services/services";
import moment from "moment";
import { constant } from "../../utils/constants";
import CompanyDropDown from "../../components/CompanyDropDown";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { getMonthName } from "../../utils/common";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: false,
    title: {
      display: true,
      text: "Total Revenue",
    },
  },
};

const Reportsindex = () => {
  const isSlider = useSlider();
  const [company, setCompany] = useState("");

  let params = {
    page: constant.PAGE_ONE,
    limit: constant.PER_PAGE_TEN,
  };
  const queryResults = useQueries({
    queries: [
      {
        queryKey: ["transactions", company?.value],
        queryFn: async () => {
          const resp =
            company?.value &&
            (await getReports({
              ...params,
              groupBy: "month",
              companyId: company?.value,
            }));
          return resp?.data?.data ?? [];
        },
      },
      {
        queryKey: ["item-report", company?.domain],
        queryFn: async () => {
          const resp =
            company?.domain && (await getItemReports(company?.domain, params));
          return resp?.data?.data ?? [];
        },
      },
    ],
  });

  const [reports, itemReport] = queryResults;

  const data = {
    labels: reports?.data?.analytics?.map((i) => getMonthName(i?.month)),
    datasets: [
      {
        data: reports?.data?.analytics?.map((i) => i?.totalRevenue),
        backgroundColor: "#3a883a",
      },
    ],
  };

  return (
    <div className="mainbox">
      <Sidebar />
      <div className={isSlider ? "body-content close" : "body-content open"}>
        <div className="d-flex align-items-baseline justify-content-between ">
          <h2 className="mainhead font-24">Reports & Analytics</h2>
          <CompanyDropDown setCompany={setCompany} company={company} />
        </div>
        <Row className="mb-3">
          <div className="d-flex align-items-baseline justify-content-between ">
            <label className="font-18 colorDark pb-3">Transactions</label>
            <p className="mb-0 text-end ">
              <Link
                className="text-decoration-none linktext"
                to="../transactions"
              >
                View All
              </Link>
            </p>
          </div>
          <Col className="tableOuter" xl={8}>
            <div className="table-responsive tableOut">
              <table className="table tableDashboard">
                <thead className="thead-dark">
                  <tr>
                    <th>Order ID</th>
                    <th>User Name</th>
                    <th>Amount</th>
                    <th>Time</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {reports?.data?.transactions?.transactions &&
                  reports?.data?.transactions?.transactions?.length > 0 ? (
                    reports?.data?.transactions?.transactions?.map(
                      (item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item?.orderId}</td>
                            <td>{item?.userDetails?.userInfo?.name}</td>
                            <td>${item?.amount}</td>
                            <td>{moment(item?.createdAt).format("lll")}</td>
                            <td>
                              <p
                                className={
                                  item?.status ==
                                  constant?.TRANSACTION_STATUS?.SUCCESS
                                    ? "greenBox"
                                    : item?.status ==
                                      constant?.TRANSACTION_STATUS?.PENDING
                                    ? "greenBox yellowBox"
                                    : "greenBox redBox"
                                }
                              >
                                {item?.status ==
                                constant?.TRANSACTION_STATUS?.SUCCESS
                                  ? "Completed"
                                  : item?.status ==
                                    constant?.TRANSACTION_STATUS?.PENDING
                                  ? "Pending"
                                  : "Cancelled"}
                              </p>
                            </td>
                          </tr>
                        );
                      }
                    )
                  ) : (
                    <tr>
                      <td colSpan={10} className="text-center">
                        No Transaction Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </Col>
          <Col xl={4}>
            <Bar options={options} data={data} />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col className="tableOuter" xl={12}>
            <div className="d-flex align-items-baseline justify-content-between">
              <label className="font-18 colorDark pb-3">
                Product Waste Tracking
              </label>
              <p className="mb-0 text-end ">
                <Link className="text-decoration-none linktext">View All</Link>
              </p>
            </div>
            <div className="table-responsive tableOut">
              <table className="table tableDashboard">
                <thead className="thead-dark">
                  <tr>
                    <th>Item Name</th>
                    <th>Location</th>
                    <th>Prepped Quantity</th>
                    <th>Waste Quantity</th>
                    <th>Unit of Measure</th>
                    <th>Number of Label Printed</th>
                    <th>Net Weight</th>
                    <th>COGs Prepped</th>
                    <th>COGs Wasted</th>
                    <th>COGs Sold</th>
                  </tr>
                </thead>

                <tbody>
                  {itemReport?.data?.wasteTrackingList?.trackingList &&
                  itemReport?.data?.wasteTrackingList?.trackingList?.length >
                    0 ? (
                    itemReport?.data?.wasteTrackingList?.trackingList?.map(
                      (item, index) => {
                        return (
                          <tr key={index}>
                            <td>Blackened</td>
                            <td>sdfsd</td>
                            <td>100</td>
                            <td>100</td>
                            <td>100</td>
                            <td>100</td>
                            <td>10kg</td>
                            <td>50</td>
                            <td>50</td>
                            <td>50</td>
                          </tr>
                        );
                      }
                    )
                  ) : (
                    <tr>
                      <td colSpan={10} className="text-center">
                        No Product Waste Tracking Found
                      </td>
                    </tr>
                  )}
                </tbody>
                <tbody></tbody>
              </table>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="tableOuter" xl={12}>
            <div className="d-flex align-items-baseline justify-content-between">
              <label className="font-18 colorDark pb-3">Item Prepared</label>
              <p className="mb-0 text-end ">
                <Link className="text-decoration-none linktext">View All</Link>
              </p>
            </div>
            <div className="table-responsive tableOut">
              <table className="table tableDashboard">
                <thead className="thead-dark">
                  <tr>
                    <th>Item Name</th>
                    <th>User or Employee</th>
                    <th>Location</th>
                    <th>Quantity of Labels Printed</th>
                    <th>Date & Time</th>
                  </tr>
                </thead>
                <tbody>
                  {itemReport?.data?.itemList?.items &&
                  itemReport?.data?.itemList?.items?.length < 0 ? (
                    itemReport?.data?.itemList?.items?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item?.en_name}</td>
                          <td>Wade Warren</td>
                          <td>13th Street 47 W 13th St, New York...</td>
                          <td>100</td>
                          <td>05/29/2024, 12:00 PM</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={10} className="text-center">
                        No Item Prepared Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Reportsindex;
