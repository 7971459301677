import { useFormik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import useSlider from "../../hooks/useSlider";
import Sidebar from "../sidebar/Sidebar";
import "./notification.css";
import DropDown from "../../components/DropDown";
import { addNotification, getAllCompany } from "../../services/services";
import { constant } from "../../utils/constants";
import { useMutation } from "@tanstack/react-query";
import { toastAlert } from "../../utils/SweetAlert";
import Loader from "../../components/Loader/Loader";

const Notification = () => {
  const isSlider = useSlider();
  const { t } = useTranslation();

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
    setFieldValue,
    setFieldTouched,
  } = useFormik({
    initialValues: {
      notifyAll: "true",
      type: constant.NOTIFICATION_TYPE.PUSH,
      subject: "",
      description: "",
      company: [],
    },
    validationSchema: yup.object().shape({
      notifyAll: yup.boolean(),
      company: yup.array().when("notifyAll", {
        is: (value) => !value,
        then: () => yup.array().min(1).label("Company"),
      }),
      subject: yup.string().required().label(t("notification.subject")),
      description: yup.string().required().label("Description"),
    }),
    onSubmit: (values) => {
      let body = {
        subject: values?.subject,
        description: values?.description,
        type: values?.type,
        notifyAll: values?.notifyAll,
      };
      if (values?.company?.length)
        body.companies = values?.company?.map((i) => i.value);

      mutation.mutate(body);
    },
  });

  const mutation = useMutation({
    mutationFn: (body) => addNotification(body),
    onSuccess: (resp) => {
      toastAlert("success", resp?.data?.message);
      resetForm();
    },
  });

  const loadCompany = async (search, loadedOptions, { page }) => {
    let resp = await getAllCompany(page, constant.PER_PAGE_TEN, search);
    let array = (await resp?.data?.data?.companies) ?? [];
    return {
      options: array.map((item) => ({
        value: item?._id,
        label: item?.name,
        domain: item?.uuid,
      })),
      hasMore: loadedOptions.length == resp?.data?.data?.total ? false : true,
      additional: {
        page: page + 1,
      },
    };
  };

  return (
    <div className="mainbox">
      <Sidebar />
      <section
        className={isSlider ? "body-content close" : "body-content open"}
      >
        <div className="container-fluid">
          <h2 className="main-heading fontSize24">
            {t("notification.notificationNews")}
          </h2>

          <div className="notifications_Box">
            <div>
              <h3 className="fontSize14">
                {t("notification.sendNoticicationTo")}:
              </h3>
              <div className="select_Role d-flex align-items-center flex-wrap">
                <div>
                  <input
                    type="radio"
                    id="allResturant"
                    name="notifyAll"
                    value={"true"}
                    checked={values?.notifyAll == "true"}
                    onChange={(e) => {
                      handleChange(e);
                      if (e.target.checked) setFieldValue("company", []);
                    }}
                    onBlur={handleBlur}
                  />
                  <label htmlFor="allResturant" className="position_Label free">
                    {t("notification.allRestaurants")}
                  </label>
                </div>
                <div className="positionLabel_Parent">
                  <input
                    type="radio"
                    id="selectedResturants"
                    name="notifyAll"
                    value={"false"}
                    checked={values?.notifyAll == "false"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <label
                    htmlFor="selectedResturants"
                    className="position_Label"
                  >
                    {t("notification.selectedResturants")}
                  </label>
                </div>
              </div>
            </div>
            {values?.notifyAll == "false" && (
              <div className="mt-4">
                <label htmlFor="name" className="companyLabel">
                  Select Restaurants
                </label>
                <DropDown
                  placeholder={t("user.selectCompany")}
                  id="company"
                  isMulti={true}
                  loadOptions={loadCompany}
                  isClearable={false}
                  onChange={(e) => {
                    setFieldValue("company", e);
                  }}
                  value={values?.company}
                  onBlur={() => setFieldTouched("company", true)}
                />
                <small className="text-danger ms-1">
                  {touched.company && errors.company}
                </small>
              </div>
            )}

            <div className="sendnotification_Parent">
              <h3 className="fontSize14">
                {t("notification.sendNotificationVia")}:
              </h3>
              <div className="select_Role d-flex align-items-center">
                <div>
                  <input
                    type="radio"
                    id="push"
                    name="type"
                    value={constant.NOTIFICATION_TYPE.PUSH}
                    checked={values?.type == constant.NOTIFICATION_TYPE.PUSH}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <label htmlFor="push" className="position_Label free">
                    {t("notification.push")}
                  </label>
                </div>
                <div className="positionLabel_Parent">
                  <input
                    type="radio"
                    id="email"
                    name="type"
                    value={constant.NOTIFICATION_TYPE.EMAIL}
                    checked={values?.type == constant.NOTIFICATION_TYPE.EMAIL}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <label htmlFor="email" className="position_Label">
                    {t("notification.email")}
                  </label>
                </div>
              </div>
            </div>

            <div className="mt-4">
              <div className="createPlanform_Child">
                <label htmlFor="subject" className="companyLabel">
                  {t("notification.subject")}{" "}
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="subject"
                  className="commonInput"
                  placeholder={t("notification.enterSubject")}
                  name="subject"
                  value={values?.subject}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <small className="text-danger ms-1">
                  {touched.subject && errors.subject}
                </small>
              </div>
              <div className="createPlanform_Child">
                <label htmlFor="name" className="companyLabel">
                  {t("notification.description")}{" "}
                  <span className="text-danger">*</span>
                </label>
                <textarea
                  id="name"
                  className="commonInput"
                  placeholder={t("notification.writeHere")}
                  rows="4"
                  cols="50"
                  name="description"
                  value={values?.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                ></textarea>

                <small className="text-danger ms-1">
                  {touched.description && errors.description}
                </small>
              </div>
            </div>

            <div className="notificationSend_Btn">
              <button
                className="userBtn btn-theme fontSize18"
                onClick={handleSubmit}
                type="button"
              >
                {t("notification.send")}
              </button>
            </div>
          </div>
        </div>
      </section>
      {mutation.isPending && <Loader />}
    </div>
  );
};

export default Notification;
